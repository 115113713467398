import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const WALLETCONNECT_PROJECT_ID = "99296b5d7acb2ca478909bd3e7b4f780";
// const ALCHEMY_ID = "vpEAMGP_rB7ZhU43ybQC6agpdVToaV5S";

// Create config for Wagmi
const config = getDefaultConfig({
  appName: "Horsly",
  projectId: WALLETCONNECT_PROJECT_ID,
  chains: [mainnet],
});

const queryClient = new QueryClient();

// RainbowKit provider component
const MyProvider = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default MyProvider;
