import React from "react";
import DashboardLayout from "../../layout/Layout";
import metamask from "../../assets/platforms/metamask.png";
import l from "../../assets/platforms/l.png";
import w from "../../assets/platforms/w.png";
import rainbow from "../../assets/platforms/rainbow.png";
import dot from "../../assets/platforms/dot.png";
import tradingView from "../../assets/platforms/tradingView.png";
import tradingLocker from "../../assets/platforms/tradeLocker.png";
import cTrader from "../../assets/platforms/cTrader.png";
import plt4 from "../../assets/platforms/4.png";
import plt5 from "../../assets/platforms/5.png";
import { MdOutlineDownload } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
import { FaApple } from "react-icons/fa";
import { ImWindows } from "react-icons/im";
import { IoLogoAndroid } from "react-icons/io5";
import { Link } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Downloads = () => {
  return (
    <DashboardLayout title="Downloads">
      <div className="mt-4">
        <h3 className="text-xl font-bold mb-3">Platforms</h3>
        <div className="my-2 flex items-center flex-wrap gap-7 justify-around">
          <div className="flex items-center flex-wrap gap-6">
            <img
              src={`${process.env.REACT_APP_API_URL}/upload/passve.svg`}
              alt="passve"
              className="size-20"
            />
            {/* <div className="flex items-center gap-2 border py-1 px-2 rounded-lg">
              <span className="pr-2 text-sm font-semibold">Connect Wallet</span>
              <img
                src={metamask}
                alt="metamask"
              />
              <img
                src={l}
                alt="l"
              />
              <img
                src={w}
                alt="w"
              />
              <img
                src={rainbow}
                alt="rainbow"
              />
              <img
                src={dot}
                alt="rainbow"
              />
            </div> */}
            <ConnectButton/>
          </div>
          <div>
            <img
              src={tradingView}
              alt="tradingView"
            />
            <div className="flex items-center justify-center gap-4 mt-3">
              <Link
                to="https://www.tradingview.com/"
                target="_blank"
              >
                <MdOutlineDownload size={22} />
              </Link>
              <Browser link="https://www.tradingview.com/" />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="my-2 flex items-center flex-wrap gap-7 justify-around">
          <div className="flex flex-col items-center flex-wrap">
            <img
              src={tradingLocker}
              alt="tradingLocker"
            />
            <div className="mt-3">
              <MdOutlineDownload size={22} />
              <div className="flex items-center flex-wrap justify-center gap-4 mt-2">
                <Mac link={"https://tradelocker.com/desktop/"} />
                <Windows link={"https://tradelocker.com/desktop/"} />
                <Browser link="https://tradelocker.com/desktop/" />
              </div>
              <div className="flex items-center flex-wrap justify-center gap-4 mt-3">
                <IOS link={"https://tradelocker.com/desktop/"} />
                <Android link={"https://tradelocker.com/desktop/"} />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center flex-wrap">
            <img
              src={cTrader}
              alt="cTrader"
            />
            <div className="mt-3">
              <MdOutlineDownload size={22} />
              <div className="flex items-center flex-wrap justify-center gap-4 mt-2">
                <Mac link={"https://ctrader.com/download"} />
                <Windows link={"https://ctrader.com/download"} />
                <Browser link="https://ctrader.com/download" />
              </div>
              <div className="flex items-center flex-wrap justify-center gap-4 mt-3">
                <IOS link={"https://ctrader.com/download"} />
                <Android link={"https://ctrader.com/download"} />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="my-2 flex items-center flex-wrap gap-7 justify-around">
          <div className="flex flex-col items-center ">
            <img
              src={plt4}
              alt="tradingLocker"
            />
            <div className="mt-3">
              <MdOutlineDownload size={22} />
              <div className="flex items-center flex-wrap justify-center gap-4 mt-2">
                <Mac link={"https://www.metatrader4.com/en/download"} />
                <Windows link={"https://www.metatrader4.com/en/download"} />
                <Browser link="https://www.metatrader4.com/en/download" />
              </div>
              <div className="flex items-center flex-wrap justify-center gap-4 mt-3">
                <IOS link={"https://www.metatrader4.com/en/download"} />
                <Android link={"https://www.metatrader4.com/en/download"} />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center ">
            <img
              src={plt5}
              alt="cTrader"
            />
            <div className="mt-3">
              <MdOutlineDownload size={22} />
              <div className="flex items-center flex-wrap justify-center gap-4 mt-2">
                <Mac link={"https://www.metatrader5.com/en/download"} />
                <Windows link={"https://www.metatrader5.com/en/download"} />
                <Browser link="https://www.metatrader5.com/en/download" />
              </div>
              <div className="flex items-center flex-wrap justify-center gap-4 mt-3">
                <IOS link={"https://www.metatrader5.com/en/download"} />
                <Android link={"https://www.metatrader5.com/en/download"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

const Browser = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className="text-sm font-semibold flex items-center gap-2 bg-black text-white px-3 py-[6px] rounded-lg">
        <CiGlobe
          fill="#FFFFFF"
          size={22}
        />
        Browser
      </button>
    </a>
  );
};

const Mac = ({ link }) => {
  return (
    <a
      href={link}
      target={"_blank"}
    >
      <button className="text-sm font-semibold flex items-center gap-2 border text-black px-3 py-[6px] rounded-lg">
        <FaApple size={22} />
        Mac
      </button>
    </a>
  );
};

const Windows = ({ link }) => {
  return (
    <a
      href={link}
      target={"_blank"}
    >
      <button className="text-sm font-semibold flex items-center gap-2 border text-black px-3 py-[6px] rounded-lg">
        <ImWindows size={22} />
        Windows
      </button>
    </a>
  );
};

const IOS = ({ link }) => {
  return (
    <a
      href={link}
      target={"_blank"}
    >
      <button className="text-sm font-semibold flex items-center gap-2 border text-black px-3 py-[6px] rounded-lg">
        <FaApple size={22} />
        IOS
      </button>
    </a>
  );
};

const Android = ({ link }) => {
  return (
    <a
      href={link}
      target={"_blank"}
    >
      <button className="text-sm font-semibold flex items-center gap-2 border text-black px-3 py-[6px] rounded-lg">
        <IoLogoAndroid size={22} />
        Android
      </button>
    </a>
  );
};

export default Downloads;
